import {
  ButtonHierarchy,
  ButtonSize,
  MainButton,
  Typography,
  TypographySize,
  TypographyWeight,
} from "@beydesign/storybook"
import { Box } from "@mui/material"
import React, { useState } from "react"
import { useLocation } from "react-router-dom"
import { useAppContext, useAppDispatchContext } from "../../app/Context"
import { getMedia } from "../../lib/getMedia"
import { StateAction } from "../../types/State"
import { VideoAndMicAccessModal } from "../Modal/VideoAndMicAccess"

const lemon = "var(--primitives-desktop-primary-brand-lemon-400)"

const tosLink =
  "https://beyond-presence.notion.site/Terms-of-Use-86de0b8f3b60441bb829efa77a269e31"
const privacyLink =
  "https://beyond-presence.notion.site/Privacy-Policy-86de0b8f3b60441bb829efa77a269e31"

interface BottomProps {
  handleGrantPermissions: (granted: boolean) => void
}

export const Bottom: React.FC<BottomProps> = ({ handleGrantPermissions }) => {
  const [permissionsModalOpen, setPermissionsModalOpen] = useState(false)
  const [errorOpen, setErrorOpen] = useState(false)

  const location = useLocation()
  const context = useAppContext()
  const dispatch = useAppDispatchContext()

  const handleAllowMicrophoneAndCamera = () => {
    setPermissionsModalOpen(true)
  }

  const requestMediaAccess = async () => {
    const stream = await getMedia(null, () => setErrorOpen(true), context)
    console.log("stream", stream)
    if (stream != null) {
      dispatch({ type: StateAction.setStream, payload: stream })
      handleGrantPermissions(true)
      setPermissionsModalOpen(false)
    }
  }

  const handleGoToLink = (link: string) => {
    window.open(link, "_blank")
  }

  return (
    <React.Fragment>
      <Box
        display={"flex"}
        flexDirection={"column"}
        justifyContent={"space-between"}
        alignItems={"center"}
        width={"100%"}
        position={"absolute"}
        bottom={10}
        gap={"var(--spacing-tokens-size-in-px-spacing-spacing-2xl)"}
      >
        <MainButton
          onClick={handleAllowMicrophoneAndCamera}
          hierarchy={ButtonHierarchy.Primary}
          size={ButtonSize.xl}
          text="Start Conversation"
        />
        <Typography
          size={TypographySize.TextS}
          weight={TypographyWeight.Medium}
          color="var(--colors-light-text-text-white)"
        >
          By clicking on "Join meeting" you agree to our{" "}
          <span
            style={{ color: lemon }}
            onClick={() => handleGoToLink(tosLink)}
          >
            Terms of Service
          </span>{" "}
          and{" "}
          <span
            style={{ color: lemon }}
            onClick={() => handleGoToLink(privacyLink)}
          >
            Privacy Policy
          </span>
          .
        </Typography>
      </Box>
      <VideoAndMicAccessModal
        open={permissionsModalOpen}
        onRequestAccess={requestMediaAccess}
      />
    </React.Fragment>
  )
}
