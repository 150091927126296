import {
  ButtonDisplayMode,
  ButtonHierarchy,
  ButtonSize,
  MainButton,
  Typography,
  TypographySize,
  TypographyWeight,
} from "@beydesign/storybook"
import { Box, Divider } from "@mui/material"
import React, { useState } from "react"
import { Agent } from "../../types/Agent"

interface AgentDescriptionProps {
  agent: Agent
  showCloseButton?: boolean
}

export const AgentDescription: React.FC<AgentDescriptionProps> = ({
  agent,
  showCloseButton = true,
}) => {
  const [isVisible, setIsVisible] = useState<boolean>(true)

  if (!isVisible) return null

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      borderRadius={"var(--spacing-tokens-size-in-px-radius-radius-lg)"}
      paddingY={"var(--spacing-tokens-size-in-px-spacing-spacing-md)"}
      paddingX={"var(--spacing-tokens-size-in-px-spacing-spacing-lg)"}
      bgcolor={"rgba(255, 255, 255, 0.6)"}
      position={"absolute"}
      top={"10px"}
      left={"10px"}
      width={"25%"}
    >
      <Box
        display={"flex"}
        flexDirection={"column"}
        gap={"var(--spacing-tokens-size-in-px-spacing-spacing-xs)"}
        position={"relative"}
      >
        <Typography
          size={TypographySize.HeadingL}
          weight={TypographyWeight.SemiBold}
        >
          Chat with {agent?.name || "Agent"}
        </Typography>
        <Divider />
        <Typography
          size={TypographySize.TextS}
          weight={TypographyWeight.Regular}
          textStyle={{
            overflow: "hidden",
            display: "-webkit-box",
            WebkitLineClamp: "3",
            WebkitBoxOrient: "vertical",
          }}
        >
          {agent?.description || "Agent description"}
        </Typography>
        {showCloseButton && (
          <Box position={"absolute"} top={"0"} right={"0"}>
            <MainButton
              displayIcon="X"
              displayIconMode={ButtonDisplayMode.Only}
              onClick={() => setIsVisible(false)}
              hierarchy={ButtonHierarchy.Tetriary}
              size={ButtonSize.xxs}
            />
          </Box>
        )}
      </Box>
    </Box>
  )
}
