import { ButtonHierarchy, ButtonSize, MainButton } from "@beydesign/storybook"
import { Box, useMediaQuery, useTheme } from "@mui/material"
import React, { useEffect, useState } from "react"
import { useAppContext, useAppDispatchContext } from "../../app/Context"
import { StateAction } from "../../types/State"
import { replaceStreamTrack } from "../../utils/media"
import { DeviceSelectionDialog } from "../MediaControlsBar/DeviceSelectionDialog"
import { MediaControlsBar } from "../MediaControlsBar/MediaControlsBar"
import { UserVideo } from "../UserVideo/UserVideo"

interface JoinMeetingModalProps {
  open: boolean
  onClose: () => void
  onJoinCall: () => void
}

interface DeviceOption {
  label: string
  deviceId: string
}

export const JoinMeetingModal: React.FC<JoinMeetingModalProps> = ({
  open,
  onClose,
  onJoinCall,
}) => {
  const [isMicEnabled, setIsMicEnabled] = useState<boolean>(true)
  const [isCameraEnabled, setIsCameraEnabled] = useState<boolean>(true)
  const [settingsOpen, setSettingsOpen] = useState(false)
  const [audioInputs, setAudioInputs] = useState<DeviceOption[]>([])
  const [videoInputs, setVideoInputs] = useState<DeviceOption[]>([])

  const context = useAppContext()
  const dispatch = useAppDispatchContext()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))

  const selectedAudioInput = context.audioDevice || ""
  const selectedVideoInput = context.videoDevice || ""

  useEffect(() => {
    const getDevices = async () => {
      try {
        const devices = await navigator.mediaDevices.enumerateDevices()

        const audioDevices = devices
          .filter((device) => device.kind === "audioinput")
          .map((device) => ({
            label:
              device.label || `Microphone ${device.deviceId.slice(0, 5)}...`,
            deviceId: device.deviceId,
          }))

        const videoDevices = devices
          .filter((device) => device.kind === "videoinput")
          .map((device) => ({
            label: device.label || `Camera ${device.deviceId.slice(0, 5)}...`,
            deviceId: device.deviceId,
          }))

        setAudioInputs(audioDevices)
        setVideoInputs(videoDevices)

        if (audioDevices.length > 0 && !selectedAudioInput) {
          dispatch({
            type: StateAction.setAudioDevice,
            payload: audioDevices[0].deviceId,
          })
        }

        if (videoDevices.length > 0 && !selectedVideoInput) {
          dispatch({
            type: StateAction.setVideoDevice,
            payload: videoDevices[0].deviceId,
          })
        }
      } catch (error) {
        console.error("Error getting media devices:", error)
      }
    }

    if (open) {
      getDevices()
    }
  }, [open, dispatch, selectedAudioInput, selectedVideoInput])

  const toggleMicrophone = async () => {
    try {
      const enabled = !isMicEnabled

      if (context.stream instanceof MediaStream) {
        const audioTracks = context.stream.getAudioTracks()
        audioTracks.forEach((track) => {
          track.enabled = enabled
        })
      }

      setIsMicEnabled(enabled)
    } catch (e) {
      console.error("Failed to toggle microphone:", e)
    }
  }

  const toggleCamera = async () => {
    try {
      const enabled = !isCameraEnabled

      if (context.stream instanceof MediaStream) {
        const videoTracks = context.stream.getVideoTracks()
        videoTracks.forEach((track) => {
          track.enabled = enabled
        })
      }

      setIsCameraEnabled(enabled)
    } catch (e) {
      console.error("Failed to toggle camera:", e)
    }
  }

  const handleSettingsClick = () => {
    setSettingsOpen(true)
  }

  const handleSettingsClose = () => {
    setSettingsOpen(false)
  }

  const handleAudioInputChange = async (deviceId: string) => {
    try {
      const audioStream = await navigator.mediaDevices.getUserMedia({
        audio: { deviceId: { exact: deviceId } },
      })

      let mediaStream: MediaStream
      if (!context.stream || !(context.stream instanceof MediaStream)) {
        mediaStream = new MediaStream()

        // Keep video track if exists
        if (context.stream instanceof MediaStream) {
          const videoTracks = context.stream.getVideoTracks()
          if (videoTracks.length > 0) {
            mediaStream.addTrack(videoTracks[0])
          }
        }
      } else {
        mediaStream = context.stream
      }

      replaceStreamTrack(mediaStream, audioStream.getAudioTracks()[0], "audio")

      dispatch({ type: StateAction.setStream, payload: mediaStream })
      dispatch({ type: StateAction.setAudioDevice, payload: deviceId })
      setIsMicEnabled(true)
    } catch (e) {
      console.error("Failed to switch audio device:", e)
    }
  }

  const handleVideoInputChange = async (deviceId: string) => {
    try {
      const videoStream = await navigator.mediaDevices.getUserMedia({
        video: { deviceId: { exact: deviceId } },
      })

      let mediaStream: MediaStream
      if (!context.stream || !(context.stream instanceof MediaStream)) {
        mediaStream = new MediaStream()

        // Keep audio track if exists
        if (context.stream instanceof MediaStream) {
          const audioTracks = context.stream.getAudioTracks()
          if (audioTracks.length > 0) {
            mediaStream.addTrack(audioTracks[0])
          }
        }
      } else {
        mediaStream = context.stream
      }

      replaceStreamTrack(mediaStream, videoStream.getVideoTracks()[0], "video")

      dispatch({ type: StateAction.setStream, payload: mediaStream })
      dispatch({ type: StateAction.setVideoDevice, payload: deviceId })
      setIsCameraEnabled(true)
    } catch (e) {
      console.error("Failed to switch video device:", e)
    }
  }

  if (!open) return null

  return (
    <Box
      position="absolute"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      width="100%"
      height="100%"
      top={0}
      left={0}
      zIndex={10}
      sx={{
        backdropFilter: "blur(10px)",
        backgroundColor: "rgba(0, 0, 0, 0.15)",
        boxShadow: "0 8px 32px 0 rgba(31, 38, 135, 0.37)",
        border: "1px solid rgba(255, 255, 255, 0.18)",
        borderRadius: "1rem",
      }}
      onClick={onClose}
    >
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        position="relative"
        width="100%"
        height="100%"
        onClick={(e) => e.stopPropagation()}
      >
        <MainButton
          onClick={onJoinCall}
          hierarchy={ButtonHierarchy.Primary}
          size={ButtonSize.xl}
          text="Join Call"
        />

        <Box
          position="absolute"
          bottom="5%"
          left="5%"
          width="25%"
          height="auto"
          sx={{
            borderRadius: "8px",
          }}
        >
          <UserVideo />
        </Box>

        <MediaControlsBar
          isMicEnabled={isMicEnabled}
          isCameraEnabled={isCameraEnabled}
          toggleMicrophone={toggleMicrophone}
          toggleCamera={toggleCamera}
          onSettingsClick={handleSettingsClick}
        />
      </Box>

      <DeviceSelectionDialog
        open={settingsOpen}
        onClose={handleSettingsClose}
        audioInputs={audioInputs}
        videoInputs={videoInputs}
        selectedAudioInput={selectedAudioInput}
        selectedVideoInput={selectedVideoInput}
        handleAudioInputChange={handleAudioInputChange}
        handleVideoInputChange={handleVideoInputChange}
      />
    </Box>
  )
}
