import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  Typography,
} from "@mui/material"
import { X } from "@phosphor-icons/react"
import React from "react"
import { Colors } from "../../constants/colors"

interface DeviceOption {
  label: string
  deviceId: string
}

interface DeviceSelectionDialogProps {
  open: boolean
  onClose: () => void
  audioInputs: DeviceOption[]
  videoInputs: DeviceOption[]
  selectedAudioInput: string
  selectedVideoInput: string
  handleAudioInputChange: (deviceId: string) => void
  handleVideoInputChange: (deviceId: string) => void
}

export const DeviceSelectionDialog: React.FC<DeviceSelectionDialogProps> = ({
  open,
  onClose,
  audioInputs,
  videoInputs,
  selectedAudioInput,
  selectedVideoInput,
  handleAudioInputChange,
  handleVideoInputChange,
}) => (
  <Dialog
    open={open}
    onClose={onClose}
    onClick={(e) => e.stopPropagation()}
    PaperProps={{
      sx: {
        backgroundColor: "rgba(0, 0, 0, 0.4)",
        backdropFilter: "blur(8px)",
        borderRadius: "24px",
        minWidth: { xs: "90vw", sm: "400px" },
        maxWidth: "500px",
        border: "1px solid rgba(255, 255, 255, 0.1)",
      },
      onClick: (e) => e.stopPropagation(),
    }}
    sx={{
      "& .MuiBackdrop-root": {
        backgroundColor: "transparent",
      },
    }}
  >
    <DialogTitle
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        color: Colors.White,
        pb: 1,
      }}
    >
      Device Settings
      <IconButton
        onClick={(e) => {
          e.stopPropagation()
          onClose()
        }}
        sx={{
          color: Colors.White,
          "&:hover": {
            backgroundColor: "rgba(255, 255, 255, 0.1)",
          },
        }}
      >
        <X size={24} />
      </IconButton>
    </DialogTitle>
    <DialogContent>
      <Box sx={{ mb: 3 }}>
        <Typography
          variant="subtitle1"
          sx={{ color: Colors.White, mb: 1, fontWeight: 500 }}
        >
          Microphone
        </Typography>
        <FormControl fullWidth>
          <Select
            value={selectedAudioInput}
            onChange={(e) => handleAudioInputChange(e.target.value)}
            onClick={(e) => e.stopPropagation()}
            sx={{
              backgroundColor: "rgba(36, 36, 36, 0.6)",
              backdropFilter: "blur(8px)",
              color: Colors.White,
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "rgba(255, 255, 255, 0.1)",
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: "rgba(255, 255, 255, 0.2)",
              },
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: Colors.Yellow,
              },
              "& .MuiSvgIcon-root": {
                color: Colors.White,
              },
              borderRadius: "12px",
            }}
            MenuProps={{
              PaperProps: {
                sx: {
                  backgroundColor: "rgba(36, 36, 36, 0.95)",
                  backdropFilter: "blur(8px)",
                  borderRadius: "12px",
                  border: "1px solid rgba(255, 255, 255, 0.1)",
                  marginTop: "8px",
                  "& .MuiMenuItem-root": {
                    color: Colors.White,
                    "&:hover": {
                      backgroundColor: "rgba(255, 255, 255, 0.1)",
                    },
                    "&.Mui-selected": {
                      backgroundColor: "rgba(220, 247, 109, 0.15)",
                      "&:hover": {
                        backgroundColor: "rgba(220, 247, 109, 0.25)",
                      },
                    },
                  },
                },
                onClick: (e) => e.stopPropagation(),
              },
            }}
          >
            {audioInputs.map((device) => (
              <MenuItem
                key={device.deviceId}
                value={device.deviceId}
                sx={{
                  "&.Mui-selected": {
                    color: Colors.Yellow,
                  },
                }}
              >
                {device.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      <Box>
        <Typography
          variant="subtitle1"
          sx={{ color: Colors.White, mb: 1, fontWeight: 500 }}
        >
          Camera
        </Typography>
        <FormControl fullWidth>
          <Select
            value={selectedVideoInput}
            onChange={(e) => handleVideoInputChange(e.target.value)}
            onClick={(e) => e.stopPropagation()}
            sx={{
              backgroundColor: "rgba(36, 36, 36, 0.6)",
              backdropFilter: "blur(8px)",
              color: Colors.White,
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "rgba(255, 255, 255, 0.1)",
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: "rgba(255, 255, 255, 0.2)",
              },
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: Colors.Yellow,
              },
              "& .MuiSvgIcon-root": {
                color: Colors.White,
              },
              borderRadius: "12px",
            }}
            MenuProps={{
              PaperProps: {
                sx: {
                  backgroundColor: "rgba(36, 36, 36, 0.95)",
                  backdropFilter: "blur(8px)",
                  borderRadius: "12px",
                  border: "1px solid rgba(255, 255, 255, 0.1)",
                  marginTop: "8px",
                  "& .MuiMenuItem-root": {
                    color: Colors.White,
                    "&:hover": {
                      backgroundColor: "rgba(255, 255, 255, 0.1)",
                    },
                    "&.Mui-selected": {
                      backgroundColor: "rgba(220, 247, 109, 0.15)",
                      "&:hover": {
                        backgroundColor: "rgba(220, 247, 109, 0.25)",
                      },
                    },
                  },
                },
                onClick: (e) => e.stopPropagation(),
              },
            }}
          >
            {videoInputs.map((device) => (
              <MenuItem
                key={device.deviceId}
                value={device.deviceId}
                sx={{
                  "&.Mui-selected": {
                    color: Colors.Yellow,
                  },
                }}
              >
                {device.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
    </DialogContent>
  </Dialog>
)
