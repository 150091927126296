import { Box } from "@mui/material"
import {
  Gear,
  IconProps,
  Microphone,
  MicrophoneSlash,
  VideoCamera,
} from "@phosphor-icons/react"
import { VideoCameraSlash } from "@phosphor-icons/react/dist/ssr"
import React from "react"
import { Colors } from "../../constants/colors"

interface MediaControlsBarProps {
  isMicEnabled: boolean
  isCameraEnabled: boolean
  toggleMicrophone: () => void
  toggleCamera: () => void
  onSettingsClick: () => void
  position?: "fixed" | "absolute"
  bottom?: string
  containerStyle?: React.CSSProperties
}

interface ContainerProps {
  display: string
  alignItems: string
  justifyContent: string
  sx: any
  width: number
  height: number
  borderRadius: string
}

const containerProps: ContainerProps = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  sx: {
    cursor: "pointer",
    backgroundColor: "rgba(36, 36, 36, 0.6)",
    "&:hover": {
      backgroundColor: "rgba(36, 36, 36, 0.8)",
    },
  },
  width: 60,
  height: 60,
  borderRadius: "50%",
}

const iconProps: IconProps = {
  size: 32,
}

export const MediaControlsBar: React.FC<MediaControlsBarProps> = ({
  isMicEnabled,
  isCameraEnabled,
  toggleMicrophone,
  toggleCamera,
  onSettingsClick,
  position = "absolute",
  bottom = "5%",
  containerStyle,
}) => {
  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="center"
      alignItems="center"
      gap={2}
      sx={{
        position: position,
        bottom: bottom,
        left: "50%",
        transform: "translateX(-50%)",
        backgroundColor: "rgba(0, 0, 0, 0.4)",
        backdropFilter: "blur(8px)",
        padding: "0.75rem",
        borderRadius: "50px",
        zIndex: 1000,
        ...containerStyle,
      }}
    >
      <Box
        {...containerProps}
        onClick={toggleCamera}
        sx={{
          ...containerProps.sx,
          backgroundColor: !isCameraEnabled ? "black" : "rgba(36, 36, 36, 0.6)",
          "&:hover": {
            backgroundColor: !isCameraEnabled
              ? "black"
              : "rgba(36, 36, 36, 0.8)",
          },
        }}
      >
        {isCameraEnabled ? (
          <VideoCamera {...iconProps} color={Colors.White} />
        ) : (
          <VideoCameraSlash {...iconProps} color={Colors.Yellow} />
        )}
      </Box>
      <Box
        {...containerProps}
        onClick={toggleMicrophone}
        sx={{
          ...containerProps.sx,
          backgroundColor: !isMicEnabled ? "black" : "rgba(36, 36, 36, 0.6)",
          "&:hover": {
            backgroundColor: !isMicEnabled ? "black" : "rgba(36, 36, 36, 0.8)",
          },
        }}
      >
        {isMicEnabled ? (
          <Microphone {...iconProps} color={Colors.White} />
        ) : (
          <MicrophoneSlash {...iconProps} color={Colors.Yellow} />
        )}
      </Box>
      <Box {...containerProps} onClick={onSettingsClick}>
        <Gear {...iconProps} color={Colors.White} />
      </Box>
    </Box>
  )
}
