import { Player } from "@lottiefiles/react-lottie-player"
import { Box, Stack, Typography, useMediaQuery } from "@mui/material"
import { useTheme } from "@mui/material/styles"
import React from "react"
import CallLoadingAnimation from "../../assets/call-loading.json"
import { Colors, isCustomBrandingEnabled } from "../../constants/colors"
import Background from "../background"
import { Header } from "../header"

export default function CallLoading() {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))

  return (
    <Stack
      direction={"column"}
      position={"absolute"}
      top={0}
      left={0}
      right={0}
      bottom={0}
      width={"100%"}
      height={"100vh"}
      overflow={"hidden"}
      zIndex={1000}
      sx={{
        bgcolor: `${Colors.White} !important`,
        color: `${Colors.Black} !important`,
      }}
    >
      <Header />
      <Box
        height={"80vh"}
        width={"100%"}
        display={"flex"}
        flexDirection={"column"}
        alignItems={"center"}
        justifyContent={"flex-start"}
        position={"relative"}
        sx={{
          padding: "25px",
        }}
      >
        {isMobile ? (
          // Mobile Layout
          <Box
            display={"flex"}
            flexDirection={"column"}
            height={"100%"}
            width={"100%"}
          >
            <Box flex={1} position={"relative"}>
              <div
                className="lk-video-conference-inner"
                style={{ height: "90%", width: "100%" }}
              >
                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  position={"relative"}
                  borderRadius={"1rem"}
                  overflow={"hidden"}
                  width={"100%"}
                  height={"100%"}
                >
                  <Box
                    sx={{
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      borderRadius: "12px",
                      background: isCustomBrandingEnabled()
                        ? "white"
                        : "linear-gradient(to right, #EAFBA4, #F5F8DD,#EBD7EF,#DDBFF6)",
                      overflow: "hidden",
                    }}
                  >
                    <Player
                      autoplay
                      loop
                      src={CallLoadingAnimation}
                      style={{ width: "100%", maxWidth: "300px" }}
                    />
                    <Typography
                      color={Colors.Purple}
                      variant="body2"
                      fontWeight={500}
                      sx={{ mt: 2, px: 2, textAlign: "center" }}
                    >
                      It may take up to 20 seconds to join the call due to high
                      traffic.
                    </Typography>
                  </Box>
                </Box>
              </div>
            </Box>
          </Box>
        ) : (
          // Desktop Layout
          <div
            className="lk-video-conference-inner"
            style={{
              width: "70%",
              marginRight: "unset",
              margin: "0px auto",
              height: "calc(100vh - 50px)",
              display: "flex",
              flexDirection: "column",
              position: "relative",
            }}
          >
            <Box
              display={"flex"}
              flexDirection={"column"}
              position={"relative"}
              borderRadius={"1rem"}
              overflow={"hidden"}
              width={"100%"}
              height={"100%"}
            >
              <Box
                sx={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: "12px",
                  background: isCustomBrandingEnabled()
                    ? "white"
                    : "linear-gradient(to right, #EAFBA4, #F5F8DD,#EBD7EF,#DDBFF6)",
                  overflow: "hidden",
                }}
              >
                <Player
                  autoplay
                  loop
                  src={CallLoadingAnimation}
                  style={{ width: "100%", maxWidth: "400px" }}
                />
                <Typography
                  color={Colors.Purple}
                  variant="body2"
                  fontWeight={500}
                  sx={{ mt: 2 }}
                >
                  It may take up to 20 seconds to join the call due to high
                  traffic.
                </Typography>
              </Box>
            </Box>
          </div>
        )}
      </Box>
      <Background />
    </Stack>
  )
}
