import { Box, useMediaQuery } from "@mui/material"
import { useTheme } from "@mui/material/styles"
import React, { useEffect, useState } from "react"
import { useAppContext, useAppDispatchContext } from "../../app/Context"
import { Agent } from "../../types/Agent"
import { StateAction } from "../../types/State"
import { JoinMeetingModal } from "../Modal/JoinMeeting"
import { UserLoginModal } from "../Modal/UserLogin"
import { AgentDescription } from "./AgentDescription"
import { Bottom } from "./Bottom"

interface AgentViewProps {
  agent: Agent
  isUserAnonymous: boolean
  permissionsGranted: boolean
  setPermissionsGranted: (granted: boolean) => void
  joinMeetingModalProps?: {
    open: boolean
    onClose: () => void
    onJoinCall: () => void
  }
}

export const AgentView: React.FC<AgentViewProps> = ({
  agent,
  isUserAnonymous,
  permissionsGranted,
  setPermissionsGranted,
  joinMeetingModalProps,
}) => {
  const [userLoginOpen, setUserLoginOpen] = useState(false)
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))

  const dispatch = useAppDispatchContext()
  const context = useAppContext()

  const { stream, user } = context || {}

  useEffect(() => {
    // Only clean up stream if we have one
    if (stream) {
      dispatch({ type: StateAction.setStream, payload: null })
    }
  }, [location.pathname, location.search])

  const handleGrantPermissions = (granted: boolean) => {
    if (granted) {
      setPermissionsGranted(granted)
      if (!user) {
        setUserLoginOpen(true)
      }
    }
  }

  return (
    <React.Fragment>
      <Box
        height={"80vh"}
        width={"100%"}
        display={"flex"}
        flexDirection={"column"}
        alignItems={"center"}
        justifyContent={"flex-start"}
        position={"relative"}
        sx={{
          padding: "25px",
        }}
      >
        {isMobile ? (
          <Box
            display={"flex"}
            flexDirection={"column"}
            height={"100%"}
            width={"100%"}
          >
            <Box flex={1} position={"relative"}>
              <div
                className="lk-video-conference-inner"
                style={{ height: "90%", width: "100%", position: "relative" }}
              >
                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  position={"relative"}
                  borderRadius={"1rem"}
                  overflow={"hidden"}
                  width={"100%"}
                  height={"100%"}
                >
                  <video
                    src="https://storage.googleapis.com/bey-dev-public/standard-template-video.mp4"
                    autoPlay
                    muted
                    loop
                    playsInline
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                      objectPosition: "center",
                    }}
                  />
                  <Box
                    position="absolute"
                    top={0}
                    left={0}
                    width="100%"
                    height="100%"
                    bgcolor="rgba(0, 0, 0, 0.5)"
                  />
                  <AgentDescription agent={agent} />
                  {!permissionsGranted && (
                    <Bottom handleGrantPermissions={handleGrantPermissions} />
                  )}
                  {joinMeetingModalProps && (
                    <JoinMeetingModal
                      open={joinMeetingModalProps.open}
                      onClose={joinMeetingModalProps.onClose}
                      onJoinCall={joinMeetingModalProps.onJoinCall}
                    />
                  )}
                  <UserLoginModal
                    open={userLoginOpen && permissionsGranted}
                    isUserAnonymous={isUserAnonymous}
                    onClose={() => setUserLoginOpen(false)}
                  />
                </Box>
              </div>
            </Box>
          </Box>
        ) : (
          <div
            className="lk-video-conference-inner"
            style={{
              width: "70%",
              marginRight: "unset",
              margin: "0px auto",
              height: "calc(100vh - 50px)",
              display: "flex",
              flexDirection: "column",
              position: "relative",
            }}
          >
            <Box
              display={"flex"}
              flexDirection={"column"}
              position={"relative"}
              borderRadius={"1rem"}
              overflow={"hidden"}
              width={"100%"}
              height={"100%"}
            >
              <video
                src="https://storage.googleapis.com/bey-dev-public/standard-template-video.mp4"
                autoPlay
                muted
                loop
                playsInline
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  objectPosition: "center",
                }}
              />
              <Box
                position="absolute"
                top={0}
                left={0}
                width="100%"
                height="100%"
                bgcolor="rgba(0, 0, 0, 0.5)"
              />
              <AgentDescription agent={agent} />
              {!permissionsGranted && (
                <Bottom handleGrantPermissions={handleGrantPermissions} />
              )}
              {joinMeetingModalProps && (
                <JoinMeetingModal
                  open={joinMeetingModalProps.open}
                  onClose={joinMeetingModalProps.onClose}
                  onJoinCall={joinMeetingModalProps.onJoinCall}
                />
              )}
              <UserLoginModal
                open={userLoginOpen && permissionsGranted}
                isUserAnonymous={isUserAnonymous}
                onClose={() => setUserLoginOpen(false)}
              />
            </Box>
          </div>
        )}
      </Box>
    </React.Fragment>
  )
}
