import {
  ButtonHierarchy,
  ButtonSize,
  MainButton,
  TextInput,
  Typography,
  TypographySize,
  TypographyWeight,
} from "@beydesign/storybook"
import { Box } from "@mui/material"
import * as Sentry from "@sentry/react"
import { getAuth, getRedirectResult, GoogleAuthProvider } from "firebase/auth"
import React, { useEffect, useState } from "react"
import { signInWithGoogle } from "../../external/auth"
import { UserVideo } from "../UserVideo/UserVideo"

interface UserLoginModalProps {
  open: boolean
  onClose: () => void
  isUserAnonymous: boolean
}

export const UserLoginModal: React.FC<UserLoginModalProps> = ({
  open,
  isUserAnonymous,
  onClose,
}) => {
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [errorMessage, setErrorMessage] = useState("")

  const auth = getAuth()

  useEffect(() => {
    const req = async () => {
      try {
        const result = await getRedirectResult(auth)
        if (result) {
          const credential = GoogleAuthProvider.credentialFromResult(result)
          const token = credential.accessToken
        }
      } catch (error) {
        setErrorMessage(error.code + " " + error.message)
        Sentry.captureException(error)
      }
    }
    req()
  }, [auth])

  const handleAnonLogin = () => {
    console.log("anon login")
  }

  const handleGoogleLogin = async () => {
    try {
      const result = await signInWithGoogle()
      if (!result.success) {
        setErrorMessage(result.error)
      } else {
        onClose()
      }
    } catch (error) {
      setErrorMessage(error.message)
      Sentry.captureException(error)
    }
  }

  if (!open) return null

  return (
    <Box
      position="absolute"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      width="100%"
      height="100%"
      top={0}
      left={0}
      zIndex={10}
      sx={{
        backdropFilter: "blur(10px)",
        backgroundColor: "rgba(0, 0, 0, 0.15)",
        boxShadow: "0 8px 32px 0 rgba(31, 38, 135, 0.37)",
        border: "1px solid rgba(255, 255, 255, 0.18)",
        borderRadius: "1rem",
      }}
      onClick={onClose}
    >
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        position="relative"
        width="100%"
        height="100%"
        onClick={(e) => e.stopPropagation()}
      >
        <Box
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"center"}
          alignItems={"center"}
          gap={"var(--spacing-tokens-size-in-px-spacing-spacing-3xl)"}
          width={"80%"}
          maxWidth={"600px"}
        >
          {isUserAnonymous && (
            <React.Fragment>
              <Typography
                text="Enter your name, email, choose a conversation language, and hit 'Start Conversation' to begin!"
                size={TypographySize.HeadingL}
                weight={TypographyWeight.SemiBold}
                color="var(--colors-light-text-text-white)"
                textStyle={{ textAlign: "center" }}
              />
              <TextInput
                placeholder="Name"
                value={name}
                onChange={(text) => setName(text)}
                style={{ width: "100%" }}
              />
              <TextInput
                placeholder="Email Address"
                value={email}
                onChange={(text) => setEmail(text)}
                style={{ width: "100%" }}
              />
              <MainButton
                onClick={handleAnonLogin}
                hierarchy={ButtonHierarchy.Primary}
                size={ButtonSize.xl}
                text="Start Conversation"
              />
            </React.Fragment>
          )}
          {!isUserAnonymous && (
            <React.Fragment>
              <Typography
                text="Login with Google to continue"
                size={TypographySize.HeadingL}
                weight={TypographyWeight.SemiBold}
                color="var(--colors-light-text-text-white)"
                textStyle={{ textAlign: "center" }}
              />
              <MainButton
                onClick={handleGoogleLogin}
                hierarchy={ButtonHierarchy.Primary}
                size={ButtonSize.xl}
                text="Login"
                leadingIcon="GoogleLogo"
                showLeadingIcon
              />
            </React.Fragment>
          )}
        </Box>
        <Box
          position="absolute"
          bottom="5%"
          left="5%"
          width="25%"
          height="auto"
          sx={{
            borderRadius: "8px",
          }}
        >
          <UserVideo />
        </Box>
      </Box>
    </Box>
  )
}
