import {
  ButtonHierarchy,
  ButtonSize,
  MainButton,
  Typography,
  TypographySize,
  TypographyWeight,
} from "@beydesign/storybook"
import { Box } from "@mui/material"
import { Microphone, VideoCamera } from "@phosphor-icons/react"
import React, { PropsWithChildren } from "react"

interface VideoAndMicAccessModalProps {
  open: boolean
  onRequestAccess: () => void
}

const iconProps = { size: 30, color: "var(--colors-light-text-text-white)" }

const IconContainer: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <Box
      display={"flex"}
      justifyContent={"center"}
      alignItems={"center"}
      borderRadius={"var(--spacing-tokens-size-in-px-radius-radius-lg)"}
      padding={"var(--spacing-tokens-size-in-px-spacing-spacing-lg)"}
      gap={"var(--spacing-tokens-size-in-px-spacing-spacing-md)"}
      bgcolor={"var(--primitives-desktop-primary-white-20)"}
    >
      {children}
    </Box>
  )
}

export const VideoAndMicAccessModal: React.FC<VideoAndMicAccessModalProps> = ({
  open,
  onRequestAccess,
}) => {
  if (!open) return null

  return (
    <Box
      position="absolute"
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      width="100%"
      height="100%"
      top={0}
      left={0}
      zIndex={10}
      sx={{
        backdropFilter: "blur(10px)",
        backgroundColor: "rgba(0, 0, 0, 0.15)",
        boxShadow: "0 8px 32px 0 rgba(31, 38, 135, 0.37)",
        border: "1px solid rgba(255, 255, 255, 0.18)",
        borderRadius: "1rem",
      }}
    >
      <Box
        display={"flex"}
        flexDirection={"column"}
        justifyContent={"center"}
        alignItems={"center"}
        gap={"var(--spacing-tokens-size-in-px-spacing-spacing-3xl)"}
        height={"100%"}
        width={"100%"}
      >
        <Box
          display={"flex"}
          flexDirection={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
          gap={"var(--spacing-tokens-size-in-px-spacing-spacing-3xl)"}
        >
          <IconContainer children={<Microphone {...iconProps} />} />
          <IconContainer children={<VideoCamera {...iconProps} />} />
        </Box>
        <Typography
          text="Please allow Camera and Mic access to proceed."
          size={TypographySize.TextL}
          weight={TypographyWeight.Medium}
          color="var(--colors-light-text-text-white)"
        />
        <MainButton
          onClick={onRequestAccess}
          text="Allow Access"
          size={ButtonSize.md}
          hierarchy={ButtonHierarchy.Primary}
        />
      </Box>
    </Box>
  )
}
